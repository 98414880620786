import React, { useState } from "react";
import { BsCurrencyBitcoin, BsTwitter, BsDiscord } from "react-icons/bs";
import { SiBinance, SiEthereum, SiDogecoin } from "react-icons/si";
import { TbCurrencySolana } from "react-icons/tb";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = () => {
  const [email, setEmail] = useState("");

  return (
    <footer className="jof-footer u-margin-top-medium">
      <div className="jof-container">
        <div className="jof-footer__coin-logos">
          <span className="">
            <BsCurrencyBitcoin />
          </span>
          <span className="">
            <SiEthereum />
          </span>
          <span className="">
            <SiDogecoin />
          </span>
          <span className="">
            <TbCurrencySolana />
          </span>
          <span className="">
            <SiBinance />
          </span>
        </div>
        <div className="jof-footer__main">
          <div className="jof-footer__logo">
            <Link to="/">
              <img
                src="img/wallet-recoveryy.png"
                alt="logo"
                style={{ width: "15rem" }}
              />{" "}
            </Link>{" "}
          </div>

          <div className="jof-footer__socials">
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillInstagram />
            </a>
            <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">
              <BsTwitter />
            </a>
            <a href="https://www.discord.com/" target="_blank" rel="noreferrer">
              <BsDiscord />
            </a>
          </div>
          <div className="jof-footer__links-form">
            <div className="jof-footer__links">
              <span>
                <Link to="/">Home</Link>
              </span>
              <span>
                <Link to="/nfts">NFTs</Link>
              </span>
              <span>
                <Link to="/wallets">Wallets</Link>
              </span>
            </div>
            <div className="jof-footer__form">
              <div className="jof-footer__form-container">
                <h4>Subscribe</h4>
                <p>
                  Join the hundreds of teams using our services, subscribe to
                  get exclusive news & offer
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    toast("You have successfully subscribed.");
                    setEmail("");
                  }}
                >
                  <input
                    type="email"
                    placeholder="email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit">SUBSCRIBE</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center u-margin-top-large mb-1">
          <span>Copyright ©2023 Pro Wallet Recovery</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
