import React from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";

const Testimonials = () => {
  return (
    <section className="jof-container jof-testimonials u-margin-top-small">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <h2 className="special-header">
            Your <br /> Trusted <br /> Pro Wallet
          </h2>
        </div>
        <div className="col-12 col-md-6">
          <img src="img/crypto-trust.png" alt="pic" className="img img-fluid" />
        </div>
      </div>
      <Container id="testimonials" className="py-5">
        <h2 className="secondary-header text-center mb-5 col-primary-dark">
          Testimonials
        </h2>
        <Carousel>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card>
                  <Card.Body>
                    <blockquote className="blockquote">
                      <p className="mb-0 carousel-text">
                        "I lost all hope of ever recovering my lost crypto funds
                        until I found Pro Wallet App. Their platform is truly
                        amazing and helped me recover all my lost funds in no
                        time. Highly recommended!"
                      </p>
                      <footer className="blockquote-footer carousel-footer">
                        Harry Friend
                      </footer>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card>
                  <Card.Body>
                    <blockquote className="blockquote">
                      <p className="mb-0 carousel-text">
                        "I can't thank the team at Pro Wallet App enough. They
                        helped me recover my lost funds and provided me with
                        excellent customer service throughout the entire
                        process. Thank you!"
                      </p>
                      <footer className="blockquote-footer carousel-footer">
                        Mike Jenas
                      </footer>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card>
                  <Card.Body>
                    <blockquote className="blockquote">
                      <p className="mb-0 carousel-text">
                        "I was skeptical at first, but Pro Wallet App proved me
                        wrong. Their platform is fast, secure, and reliable. I
                        would recommend their services to anyone in need of
                        crypto wallet recovery."
                      </p>

                      <footer className="blockquote-footer carousel-footer">
                        Tom simon
                      </footer>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card>
                  <Card.Body>
                    <blockquote className="blockquote">
                      <p className="mb-0 carousel-text">
                        "The team at Pro Wallet App went above and beyond to
                        help me recover my lost funds. They truly care about
                        their customers and are dedicated to providing the best
                        service possible."
                      </p>

                      <footer className="blockquote-footer carousel-footer">
                        Jannet Ball
                      </footer>
                    </blockquote>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
};

export default Testimonials;
