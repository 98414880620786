import React, { useEffect, useState } from "react";
import Meta from "../../components/Meta";
import WalletConnect from "../../components/WalletConnect";
import { Footer, Navbar, Sidebar } from "../../components";

const Wallet = () => {
  const [wallet, setWallet] = useState("");

  const handleSelected = (name) => {
    setWallet(name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title={"Wallet"} />
      <Navbar />
      <Sidebar />
      <div className="jof-wallet pt-md-5">
        <div className="container">
          <div className="row text-center justify-content-center">
            <h2 className="secondary-header u-margin-bottom-small u-margin-top-small">
              CONNECT YOUR WALLET
            </h2>
            <div className="col-12 col-md-6">
              <p className="jof-paragraph-1">
                To access your funds and recover your lost wallet, you'll need
                to connect your wallet to our platform. Don't worry, the process
                is quick and easy, and we support a variety of popular wallets.
              </p>
            </div>
          </div>
          <div className="row mb-5 text-center justify-content-center u-margin-top-small">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Blockchain")}
              >
                <img
                  src="img/crypto-1.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Blockchain</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Coinbase")}
              >
                <img
                  src="img/crypto-20.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Coinbase</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("MetaMask")}
              >
                <img
                  src="img/crypto-19.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">MetaMask</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Trust")}
              >
                <img
                  src="img/crypto-18.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Trust</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Aktionariat")}
              >
                <img
                  src="img/crypto-2.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Aktionariat</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Binance Smart Chain")}
              >
                <img
                  src="img/crypto-3.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Binance Smart Chain</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Dok Wallet")}
              >
                <img
                  src="img/crypto-4.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Dok Wallet</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("MEET.ONE")}
              >
                <img
                  src="img/crypto-5.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">MEET.ONE</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("SparkPoint")}
              >
                <img
                  src="img/crypto-6.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">SparkPoint</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Bridge Wallet")}
              >
                <img
                  src="img/crypto-7.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Bridge Wallet</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Ownbit")}
              >
                <img
                  src="img/crypto-8.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Ownbit</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Wallet.io")}
              >
                <img
                  src="img/crypto-9.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Wallet.io</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("SafePal")}
              >
                <img
                  src="img/crypto-10.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">SafePal</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("GridPlus")}
              >
                <img
                  src="img/crypto-11.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">GridPlus</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("BitPay")}
              >
                <img
                  src="img/crypto-12.png"
                  alt="Crypto"
                  jof-wallet__crypto-colalt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">BitPay</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("MathWallet")}
              >
                <img
                  src="img/crypto-13.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">MathWallet</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Maiar")}
              >
                <img
                  src="img/crypto-14.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Maiar</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Anchor")}
              >
                <img
                  src="img/crypto-15.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Anchor</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Rainbow")}
              >
                <img
                  src="img/crypto-16.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Rainbow</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Atomic")}
              >
                <img
                  src="img/crypto-17.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Atomic</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Phantom")}
              >
                <img
                  src="img/crypto-21.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Phantom</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("XDC Wallet")}
              >
                <img
                  src="img/crypto-22.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">XDC Wallet</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("CoolWallet S")}
              >
                <img
                  src="img/crypto-25.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">CoolWallet S</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Coin98")}
              >
                <img
                  src="img/crypto-24.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Coin98</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Walleth")}
              >
                <img
                  src="img/crypto-26.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Walleth</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Huobi Wallet")}
              >
                <img
                  src="img/crypto-27.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Huobi Wallet</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("TokenPocket")}
              >
                <img
                  src="img/crypto-28.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">TokenPocket</h4>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Gnosis Safe Multisig")}
              >
                <img
                  src="img/crypto-29.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Gnosis Safe Multisig</h4>
              </div>
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center u-margin-top-small text-center">
            <div className="col-12 col-md-3 mb-4 mb-md-0 jof-wallet__crypto-col">
              <div
                className="jof-wallet__crypto"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleSelected("Crypto.com")}
              >
                <img
                  src="img/crypto-32.png"
                  alt="Crypto"
                  width="100"
                  className="img-fluid"
                />
                <h4 className="fw-bold mt-2">Crypto.com</h4>
              </div>
            </div>
          </div>
        </div>
        <WalletConnect currentWallet={wallet} />
      </div>
      <Footer />
    </>
  );
};

export default Wallet;
