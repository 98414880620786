import React, { useState } from 'react';
import { Routes as Routers, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { NotFound } from './components';
import { Home, NFT, Wallet } from './pages';
import Connect from './components/Connect';
import { SidebarContext } from './context/sidebarContext';

import './styles/App.scss';

function App() {
  const [sidebarActive, setSidebarActive] = useState(false);

  return (
    <SidebarContext.Provider value={{ sidebarActive, setSidebarActive }}>
      <div className='app'>
        <Connect />

        <main>
          <Routers>
            <Route path='/wallets' element={<Wallet />} />
            <Route path='/nfts' element={<NFT />} />
            <Route path='/' element={<Home />} />
            <Route path='*' element={<NotFound />} />
          </Routers>
        </main>
        <ToastContainer
          position='bottom-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
        />
      </div>
    </SidebarContext.Provider>
  );
}

export default App;
