import React from 'react';
import { Link } from 'react-router-dom';

const CryptoBg = () => {
  return (
    <section className='crypto-bg u-margin-top-medium'>
      <div className='crypto-bg__btn'>
        <Link to='/wallets'>Recover Now!</Link>{' '}
      </div>
    </section>
  );
};

export default CryptoBg;
