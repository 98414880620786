import React, { useEffect, useState } from 'react';
import Meta from '../../components/Meta';
import WalletConnect from '../../components/WalletConnect';
import { Footer, Navbar, Sidebar } from '../../components';

const NFT = () => {
  const [wallet, setWallet] = useState('');

  const handleSelected = (name) => {
    setWallet(name);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title={'NFTs'} />
      <Navbar />
      <Sidebar />

      <div className='jof-container jof-nft pt-md-5'>
        <div className='container'>
          <div className='row text-center justify-content-center'>
            <h2 className='secondary-header mb-3'>CONNECT NFT PROVIDER</h2>
            <div className='col-12 col-md-6'>
              <p className='jof-paragraph-1'>
                Ready to start managing your NFTs in one place safely and
                confidently? Connect with one of our available providers.
              </p>
            </div>
          </div>
          <div className='row text-center justify-content-center'>
            <div className='col-12 col-md-6'>
              <ul className='list-group u-margin-top-small u-margin-bottom-large jof-nft__list'>
                <li
                  onClick={() => handleSelected('MetaMask')}
                  className='list-group-item d-flex py-3 justify-content-between align-items-start jof-nft__list-item'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                >
                  <div className='ms-2'>
                    <img
                      src='img/crypto-19.png'
                      alt='Crypto'
                      className='img-fluid jof-nft__logo'
                    />
                  </div>

                  <div className='ms-2 me-auto'>
                    <div className='fw-bold'>MetaMask</div>
                  </div>
                  <span className='badge bg-primary rounded-pill'>Popular</span>
                </li>
                <li
                  onClick={() => handleSelected('Coinbase Wallet')}
                  className='list-group-item d-flex py-3 justify-content-between align-items-start jof-nft__list-item'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                >
                  <div className='ms-2'>
                    <img
                      src='img/crypto-20.png'
                      alt='Crypto'
                      className='img-fluid jof-nft__logo'
                    />
                  </div>
                  <div className='ms-2 me-auto'>
                    <div className='fw-bold'>Coinbase Wallet</div>
                  </div>
                </li>
                <li
                  onClick={() => handleSelected('SafePal')}
                  className='list-group-item d-flex py-3 justify-content-between align-items-start jof-nft__list-item'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                >
                  <div className='ms-2'>
                    <img
                      src='img/crypto-10.png'
                      alt='Crypto'
                      className='img-fluid jof-nft__logo'
                    />
                  </div>
                  <div className='ms-2 me-auto'>
                    <div className='fw-bold'>SafePal</div>
                  </div>
                </li>
                <li
                  onClick={() => handleSelected('Phantom')}
                  className='list-group-item d-flex py-3 justify-content-between align-items-start jof-nft__list-item'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                >
                  <div className='ms-2'>
                    <img
                      src='img/crypto-21.png'
                      alt='Crypto'
                      className='img-fluid jof-nft__logo'
                    />
                  </div>
                  <div className='ms-2 me-auto'>
                    <div className='fw-bold'>Phantom</div>
                  </div>
                  <span className='badge bg-secondary rounded-pill'>
                    Solana
                  </span>
                </li>
                <li
                  onClick={() => handleSelected('Trust Wallet')}
                  className='list-group-item d-flex py-3 justify-content-between align-items-start jof-nft__list-item'
                  data-bs-toggle='modal'
                  data-bs-target='#staticBackdrop'
                >
                  <div className='ms-2'>
                    <img
                      src='img/crypto-18.png'
                      alt='Crypto'
                      className='img-fluid jof-nft__logo'
                    />
                  </div>
                  <div className='ms-2 me-auto'>
                    <div className='fw-bold'>Trust Wallet</div>
                  </div>
                  <span className='badge bg-primary rounded-pill'>Popular</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <WalletConnect currentWallet={wallet} />
      </div>
      <Footer />
    </>
  );
};

export default NFT;
