import React from "react";
import { GiWallet } from "react-icons/gi";
import { FaSearchDollar, FaShieldAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <section className="jof-container u-margin-top-large" id="how-it-works">
      <h2 className="secondary-header text-center u-margin-bottom-medium">
        How It works
      </h2>
      <div className="row text-center gap-5 gap-md-0">
        <div className="col-12 col-md-4">
          <Link to="/wallets">
            <div
              className="card mb-3 border-primary jof-card-1"
              style={{ maxWidth: "30rem" }}
            >
              <div className="card-header jof-card-1__header">
                <GiWallet size={60} color="white" />
              </div>
              <div className="card-body jof-card-1__body">
                <h3 className="card-title jof-card-1__title">
                  Connect Your Wallet
                </h3>
                <p className="card-text jof-card-1__text">
                  Connect your wallet to our platform using the supported wallet
                  options. This will allow us to scan the blockchain for any
                  lost or missing funds.
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-md-4">
          <div
            className="card mb-3 border-primary jof-card-1"
            style={{ maxWidth: "30rem" }}
          >
            <div className="card-header jof-card-1__header">
              <FaShieldAlt size={60} color="white" />
            </div>
            <div className="card-body jof-card-1__body">
              <h3 className="card-title jof-card-1__title">
                Verify Your Identity
              </h3>
              <p className="card-text jof-card-1__text">
                To ensure the security of your account and personal information,
                we'll need to verify your identity before proceeding with the
                recovery process.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div
            className="card mb-3 border-primary jof-card-1"
            style={{ maxWidth: "30rem" }}
          >
            <div className="card-header jof-card-1__header">
              <FaSearchDollar size={60} color="white" />
            </div>
            <div className="card-body jof-card-1__body">
              <h3 className="card-title jof-card-1__title">
                Recover Your Funds
              </h3>
              <p className="card-text jof-card-1__text">
                Once your identity is verified, our platform will automatically
                scan the blockchain for any lost or missing funds. If we find
                any, we'll notify you immediately and guide you through the
                recovery process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
