import React from 'react';
import { ColorRing } from 'react-loader-spinner';

const Loading = ({ color, size, visible, ariaLabel, wrapperClass }) => {
  return (
    <ColorRing
      visible={visible}
      height={size}
      width={size}
      ariaLabel={ariaLabel}
      wrapperStyle={{}}
      wrapperClass={wrapperClass}
      colors={[color, color, color, color, color]}
    />
  );
};

Loading.defaultProps = {
  color: 'white',
  size: 50,
  visible: true,
  ariaLabel: 'blocks-loading',
  wrapperClass: 'blocks-wrapper',
};

export default Loading;
