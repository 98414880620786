import React from "react";
import { AiFillLock } from "react-icons/ai";
import { FaUsers, FaMobile, FaClock } from "react-icons/fa";

const Features = () => {
  return (
    <section className="jof-container u-margin-top-medium" id="features">
      <h2 className="secondary-header text-center u-margin-bottom-medium">
        Features
      </h2>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 jof-features__list">
          <div className="jof-features__item u-margin-bottom-small">
            <div className="jof-features__item-icon">
              <span>
                <AiFillLock />
              </span>
            </div>
            <div className="jof-features__item-body">
              <h4>Secure Platform</h4>
              <p>
                Our platform is built with top-notch security features to ensure
                the safety of your personal information and funds. We also have
                a team of security experts that monitor our platform around the
                clock.
              </p>
            </div>
          </div>
          <div className="jof-features__item u-margin-bottom-small">
            <div className="jof-features__item-icon">
              <span>
                <FaClock />
              </span>
            </div>
            <div className="jof-features__item-body">
              <h4>Fast Recovery Time</h4>
              <p>
                With our advanced blockchain scanning technology, we're able to
                recover your lost or missing funds in record time. Say goodbye
                to long waiting periods and hello to quick results.
              </p>
            </div>
          </div>
          <div className="jof-features__item u-margin-bottom-small">
            <div className="jof-features__item-icon">
              <span>
                <FaMobile />
              </span>
            </div>
            <div className="jof-features__item-body">
              <h4>Mobile App</h4>
              <p>
                Take our platform with you on the go with our mobile app.
                Recover your lost or missing funds from anywhere in the world
                with just a few taps on your phone.
              </p>
            </div>
          </div>
          <div className="jof-features__item u-margin-bottom-small">
            <div className="jof-features__item-icon">
              <span>
                <FaUsers />
              </span>
            </div>
            <div className="jof-features__item-body">
              <h4>Dedicated Support</h4>
              <p>
                Our team of experts is always ready to assist you with any
                questions or issues you may have. Contact us anytime via our
                dedicated support channels.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <img
            src="img/blockchain.png"
            alt="features"
            className="img img-fluid"
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
