export const wallets = [
  "SafePal",
  "Trust Wallet",
  "MetaMask",
  "Pera Algo Wallet",
  "Luno",
  "SafeMoon",
  "Phantom",
  "Tronlink Pro",
  "Slope Wallet",
  "Enjin Wallet",
  "Coinbase Wallet",
  "Crypto.com",
  "Chippa Wallet",
  "Atomic Wallet",
  "Electrum",
  "Coinomi",
  "Methodology",
  "Exodus",
  "Ledger blue",
  "Trezor",
  "Dash Wallet",
  "Dok Wallet",
  "MEET.ONE",
  "MathWallet",
  "Rainbow",
  "Binance Smart Chain",
  "Coin98",
  "Huobi Wallet",
  "Token Pocket",
  "Walleth",
  "Ownbit",
];

export const networks = [
  "AVAX C-Chain",
  "Aptos",
  "Aptos-Dev",
  "Arbitrum",
  "Aurora",
  "BNB Beacon Chain",
  "BNB Smart Chain",
  "Bitcoin",
  "Bitcoin Cash",
  "Boba",
  "Cardano",
  "Celo",
  "Cosmos",
  "Cronos",
  "Dash",
  "DigiByte",
  "Dogecoin",
  "EOS",
  "Ethereum",
  "Ethereum Classis",
  "EthereumPoW",
  "Fantom",
  "Flare",
  "Fuse",
  "Gnosis",
  "Godwoken",
  "Godwoken V1",
  "Harmony",
  "Heco",
  "Injective",
  "Klaytn",
  "KCC",
  "Kusama",
  "Litecoin",
  "Matic",
  "Metis",
  "Moonbeam",
  "NEO",
  "Optimism",
  "Osmosis",
  "Polkadot",
  "Polygon",
  "QTUM",
  "RSK",
  "Ripple",
  "Solana",
  "Songbird",
  "Stellar",
  "Syscoin",
  "TON",
  "Terra",
  "Terra Classic",
  "Theta",
  "Tron",
  "VeChain",
  "XinFin",
  "Zcash",
];
