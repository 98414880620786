import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { SidebarContext } from "../context/sidebarContext";

const Navbar = () => {
  const { sidebarActive, setSidebarActive } = useContext(SidebarContext);

  return (
    <div className="jof-navbar container-fluid">
      <div className="jof-navbar__logo">
        <NavLink to="/">
          {" "}
          <img
            src="img/wallet-recoveryy.png"
            alt="logo"
            style={{ width: "10rem" }}
          />
        </NavLink>{" "}
      </div>

      <div className="jof-navbar__items">
        <span className="jof-navbar__item">
          <NavLink to="/">Home</NavLink>
        </span>
        <span className="jof-navbar__item">
          <a href="/#how-it-works">How it works</a>
        </span>
        <span className="jof-navbar__item">
          <a href="/#features">Features</a>
        </span>
        <span className="jof-navbar__item">
          <a href="/#testimonials">Testimonials</a>
        </span>
        <span className="jof-navbar__item">
          <NavLink to="/nfts">NFTs</NavLink>
        </span>
        <span className="jof-navbar__item">
          <NavLink to="/wallets">Wallets</NavLink>
        </span>
      </div>
      <div
        className="jof-navbar__menu"
        onClick={() => setSidebarActive(!sidebarActive)}
      >
        <i className="bi bi-list"></i>
      </div>
    </div>
  );
};

export default Navbar;
