import React from "react";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import CryptoBg from "./components/CryptoBg";
import ContactUs from "./components/ContactUs";
import Meta from "../../components/Meta";
import { Footer, Navbar, Sidebar } from "../../components";

const home = () => {
  return (
    <>
      <Meta title={"Pro Wallet Recovery App"} />
      <Navbar />
      <Sidebar />
      <div>
        <Hero />
        <HowItWorks />
        <Features />
        <CryptoBg />
        <Testimonials />
        <ContactUs />
      </div>
      <Footer />
    </>
  );
};

export default home;
