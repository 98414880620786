import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Pro Wallet",
  description:
    "Connect your wallet to our platform and recover your lost or missing funds in no time. Our platform is secure, easy to use, and fast.",
  keywords: "prorecoveryapp wallet recovery recover wallet prorecoveryapp.com",
};

export default Meta;
