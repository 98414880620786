import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SidebarContext } from '../context/sidebarContext';

const Sidebar = () => {
  const { sidebarActive, setSidebarActive } = useContext(SidebarContext);

  return (
    <>
      <div
        className={`jof-sidebar ${
          sidebarActive ? 'jof-sidebar__out' : 'jof-sidebar__in'
        }`}
      >
        <div className='jof-sidebar__header'>
          <div className='jof-sidebar__title'>
            {' '}
            <img
              src='img/wallet-recoveryy.png'
              alt='logo'
              style={{ width: '8rem', height: '6rem' }}
            />
          </div>
          <div
            className='jof-sidebar__btn-close'
            onClick={() => setSidebarActive(!sidebarActive)}
          >
            <i className='bi bi-x-lg'></i>
          </div>
        </div>
        <div className='jof-sidebar__body'>
          <ul className='navbar-nav justify-content-end flex-grow-1 pe-3 jof-sidebar__list'>
            <li
              className='nav-item jof-sidebar__item'
              onClick={() => setSidebarActive(!sidebarActive)}
            >
              <Link className='' aria-current='page' to='/'>
                Home
              </Link>
            </li>
            <li
              className='nav-item jof-sidebar__item'
              onClick={() => setSidebarActive(!sidebarActive)}
            >
              <a className='' aria-current='page' href='/#how-it-works'>
                How it works
              </a>
            </li>
            <li
              className='nav-item jof-sidebar__item'
              onClick={() => setSidebarActive(!sidebarActive)}
            >
              <a className='' aria-current='page' href='/#features'>
                Features
              </a>
            </li>
            <li
              className='nav-item jof-sidebar__item'
              onClick={() => setSidebarActive(!sidebarActive)}
            >
              <a className='' aria-current='page' href='/#testimonials'>
                Testimonials
              </a>
            </li>
            <li
              className='nav-item jof-sidebar__item'
              onClick={() => setSidebarActive(!sidebarActive)}
            >
              <Link className='' aria-current='page' to='/nfts'>
                NFTs
              </Link>
            </li>
            <li
              className='nav-item jof-sidebar__item'
              data-bs-dismiss='offcanvas'
              onClick={() => setSidebarActive(!sidebarActive)}
            >
              <Link className='' aria-current='page' to='/wallets'>
                Wallets
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
