import React from "react";

const WalletConnect = ({ currentWallet }) => {
  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered jof-wallet-connect">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-3 col-primary-dark fw-bold">
              {currentWallet}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h2
              className="modal-title fs-4 mb-2 text-center text-danger"
              id="staticBackdropLabel"
            >
              No wallet detected!
            </h2>
            <div className="text-center">
              <button
                type="button"
                className="jof-btn-style fs-5"
                data-bs-toggle="modal"
                data-bs-target="#DAppConnectModal"
                data-bs-dismiss="modal"
              >
                Connect Manually
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <h2 className="fs-5">Pro Wallet</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletConnect;
