import http from './httpService';

const apiEndpoint = '/enquiries';

export function postEnquiry(data) {
  return http.post(`${apiEndpoint}`, {
    name: data.name,
    email: data.email,
    message: data.message,
  });
}
