import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import { postEnquiry } from '../../../services/enquiryService';
import { toast } from 'react-toastify';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    const formData = {
      name,
      email,
      message,
    };

    try {
      const { data } = await postEnquiry(formData);
      setName('');
      setEmail('');
      setMessage('');
      toast(data.message);
    } catch (error) {
      if (error.response && error.response?.data) {
        toast(error.response.data.message);
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <section className='jof-container jof-contact-us'>
      <Container id='contact' className='py-5'>
        <h2 className='text-center text-bold mb-5 tertiary-header-dark'>
          Leave a message
        </h2>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <form onSubmit={(e) => handleSubmit(e)} className='jof-form'>
              <div className='jof-form__control'>
                <label htmlFor='name'>Name</label>
                <input
                  type='text'
                  id='name'
                  placeholder='full name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className='jof-form__control'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  id='email'
                  placeholder='email address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='jof-form__control'>
                <label htmlFor='message'>Message</label>
                <textarea
                  name='message'
                  id='message'
                  placeholder='message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className='jof-form__btn'>
                <button disabled={loadingSubmit}>
                  {' '}
                  {loadingSubmit ? <Loading size={28} /> : 'Submit'}
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
