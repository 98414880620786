import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="jof-container jof-home__sect-1" id="hero">
      <div className="row pt-md-5">
        <div className="col-12 col-md-6">
          <h1 className="primary-header u-margin-bottom-small display-desktop">
            Recover Your Lost Crypto Funds with Ease
          </h1>
          <h1 className="primary-header u-margin-bottom-small display-mobile">
            Recover Your <br /> Lost Crypto Funds <br /> with Ease
          </h1>
          <p className="lead primary-paragraph u-margin-bottom-small">
            Connect your wallet to our platform and recover your lost or missing
            funds in no time. Our platform is secure, easy to use, and fast.
          </p>
          <button className="jof-btn-primary">
            <Link to="/wallets">Get Started</Link>{" "}
          </button>
        </div>
        <div className="col-12 col-md-6">
          <img
            className="img img-fluid"
            alt="hero"
            src="img/cryptocurrency.png"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
