import React from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

const SuccessfulScreen = ({ successConnect }) => {
  return (
    <>
      {successConnect && (
        <div className='jof-success-connect'>
          <div className='secondary-header u-margin-bottom-medium'>
            Thank You
          </div>
          <p className='primary-paragraph u-margin-bottom-medium display-desktop'>
            Your submission has been received
          </p>
          <p className='primary-paragraph u-margin-bottom-small display-mobile'>
            Your submission <br /> has been received
          </p>
          <div className='d-flex'>
            <a href='/' className='text-dark'>
              <HiOutlineArrowNarrowLeft fontSize={22} />
            </a>
            <p className='ms-5 primary-paragraph'>Back to home</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessfulScreen;
